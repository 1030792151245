
.earn .container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    padding: 3rem 1rem;
}

.right .income p{
    padding: 2rem 0;
}

.earn img{
    max-width: 300px;
    transition: all .5s ease-in-out;
    cursor: pointer;
}

.earn img:hover{
    transform: scale(1.1);
}


@media screen and (max-width: 940px) {
    .earn .container{
        grid-template-columns: 1fr;
    }
}