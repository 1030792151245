.header{
    width: 100%;
    height: 90px;
    border-bottom: 1px solid #eee;
    position: sticky;
    background: #fff;
    top: 0;
    left: 0;
    z-index: 10;
}

.container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 1rem;
}

.colors{
    color: var(--primary);
}

.nav-menu{
    display: flex;
}

.nav-menu li{
    padding: 0 1rem;
}

.nav-menu a{
    font-weight: 600;
    font-size: 1rem;
    color: #333;
}

.hamburger{
    display: none;
    cursor: pointer;
}

@media screen and (max-width: 1240px) {


    .hamburger{
        display: flex;
        
    }

    .nav-menu{
        position: absolute;
        flex-direction: column;
        right: -100%;
        top: 90px;
        justify-content: start;
        width: 50%;
        height: 100vh;
        transition: 0.4s;
        z-index: 2;
        background: #fff;
        border-left: 1px solid #eee;
        
    }

    .nav-menu.active{
        right: 0;
    }

    .nav-menu li{
        padding: 1rem;
        border-bottom: 1px solid #eee;
        max-width: 100%;
    }
}